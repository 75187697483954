import { Button } from "@mui/material";
import ContianerWapper from "components/ContainerWrapper";
import DataTableServerSide, { ColumnDef } from "components/DataTableServerSide";
import Iconify from "components/iconify";
import moment from "moment-timezone";
import { useMemo } from "react";
import { RouterLink } from "routes/components";
import { orderType, status } from "utils/hebrew";

// interface Order {
//   id: string;
//   order_date: Date;
//   itemCount: number;
//   status: string;
//   from_location: string;
//   to_location: string;
// }

const OrdersView = () => {

  const columns = useMemo<ColumnDef[]>(
    () => [
      { accessorKey: "id", header: "מספר הזמנה" },
      {
        id: "type",
        accessorFn: (row) => orderType[row.type],
        header: "סוג",
        filterVariant: "autocomplete",
        filterSelectOptions: Object.entries(orderType).map(([key, value]) => ({ value: key, label: value })),
      },
      {
        accessorKey: "order_date",
        Cell: ({ cell }) => moment(cell.getValue<string>()).format("DD/MM/YYYY HH:mm"),
        header: "תאריך הזמנה",
        filterVariant: "datetime-range",
      },
      { accessorKey: "item_count", header: "מספר פריטים" },
      {
        id: "status",
        accessorFn: (row) => status[row.status],
        header: "סטטוס הזמנה",
        filterVariant: "autocomplete",
        filterSelectOptions: Object.entries(status).map(([key, value]) => ({ value: key, label: value })),
      },
      { accessorKey: "from_location", header: "ממקום", filterVariant: "autocomplete" },
      { accessorKey: "to_location", header: "למקום", filterVariant: "autocomplete" },
      { accessorKey: "username", header: "משתמש", filterVariant: "autocomplete" },
    ],
    []
  );

  return (
    <ContianerWapper
      title="הזמנות"
      secondaryAction={
        <Button
          LinkComponent={RouterLink}
          href="/orders/new"
          variant="contained"
          color="success"
          startIcon={<Iconify icon="mdi:plus-thick" />}
        >
          הזמנה חדשה
        </Button>
      }
    >
      <DataTableServerSide invalidateKey="orders" getUrl="/orders/" columns={columns} />
    </ContianerWapper>
  );
};

export default OrdersView;
