import { Button, debounce, Menu, MenuItem, Stack, Typography } from "@mui/material";
import {
  keepPreviousData,
  useQuery
} from '@tanstack/react-query';
import axios from "axios";
import {
  MaterialReactTable,
  MRT_Cell,
  MRT_Row,
  MRT_TableInstance,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState
} from "material-react-table";
import { MRT_Localization_HE } from "material-react-table/locales/he";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import Iconify from "./iconify";
import moment from "moment";

export interface ColumnDef extends MRT_ColumnDef<any> {
  filterVariant?: "autocomplete" | "date-range" | "time-range" | "text" | "checkbox" | "datetime-range";
}

interface DataTableServerSideProps {
  invalidateKey: string;
  getUrl: string;
  rowPreperation?: (row: any) => any;
  columns: ColumnDef[];
  exportFileName?: string;
  getRowId?: (row: any) => string;
  actions?: (props: {
    cell: MRT_Cell<any, unknown>;
    row: MRT_Row<any>;
    staticRowIndex?: number;
    table: MRT_TableInstance<any>;
  }) => React.ReactNode;
}

interface Data {
  data: any[];
  total_rows: number;
  distinct_items: Record<string, any[]>;
}


const DataTableServerSide: React.FC<DataTableServerSideProps> = ({
  columns,
  getRowId,
  actions,
  getUrl,
  rowPreperation,
  invalidateKey,
  exportFileName = "table_export",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const contentRef = React.useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const { filterDefs, columnsMemo }: any = useMemo(() => {

    const filterDefs: Record<string, string> = {};
    columns.forEach((column) => {
      if (column.filterVariant === undefined) {
        column.enableColumnFilter = false;
      }
      else {
        filterDefs[column.id ?? column.accessorKey ?? ''] = column.filterVariant;
      }
    });
    return { filterDefs, columnsMemo: columns };
  }, [columns]);

  const {
    data: {
      data = [],
      total_rows,
      distinct_items,
    } = {},
    isError,
    isRefetching,
    isLoading,
  } = useQuery<Data>({
    queryKey: [
      invalidateKey,
      columnFilters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const params: Record<string, string> = {
        "limit": pagination.pageSize.toString(),
        "offset": (pagination.pageIndex * pagination.pageSize).toString(),
      };

      sorting.forEach((sort, index) => {
        params[`sorts[${index}][${sort.id}]`] = sort.desc ? 'DESC' : 'ASC';
      })

      columnFilters.forEach((filter) => {
        switch (filterDefs?.[filter.id]) {
          case 'autocomplete':
            params[`filters[${filter.id}][eq]`] = filter.value as string;
            break;
          case 'text':
            params[`filters[${filter.id}][icontains]`] = filter.value as string;
            break;
          case 'checkbox':
            params[`filters[${filter.id}][eq]`] = filter.value as string;
            break;
          case 'date-range':
            const value = filter.value as any[];
            if (value[0] !== '' && value[0] !== undefined) {
              params[`filters[${filter.id}][gte]`] = value[0].format('YYYY-MM-DD');
            }
            if (value[1] !== '' && value[1] !== undefined) {
              params[`filters[${filter.id}][lte]`] = value[1].format('YYYY-MM-DD');
            }
            break;
          case 'time-range':
            const value2 = filter.value as any[];
            if (value2[0] !== '' && value2[0] !== undefined) {
              params[`filters[${filter.id}][gte]`] = value2[0].format('HH:mm:ss');
            }
            if (value2[1] !== '' && value2[1] !== undefined) {
              params[`filters[${filter.id}][lte]`] = value2[1].format('HH:mm:ss');
            }
            break;
          case 'datetime-range':
            const value3 = filter.value as any[];
            if (value3[0] !== '' && value3[0] !== undefined) {
              params[`filters[${filter.id}][gte]`] = value3[0].format('YYYY-MM-DDTHH:mm:ssZ');
            }
            if (value3[1] !== '' && value3[1] !== undefined) {
              params[`filters[${filter.id}][lte]`] = value3[1].format('YYYY-MM-DDTHH:mm:ssZ');
            }
            break;
        }
      });
      setSearchParams(params);
      const resposne = await axios.get(getUrl, { params });
      if (rowPreperation) {
        resposne.data.data.forEach(rowPreperation);
      }
      return resposne.data;
    },
    placeholderData: keepPreviousData,
  });


  const handleColumnFiltersChange: any = debounce((updaterOrValue: any) => {
    setColumnFilters((prevFilters) => {
      const newFilters =
        typeof updaterOrValue === 'function'
          ? (updaterOrValue as any)(prevFilters)
          : updaterOrValue;

      let flag = false;
      for (const filter of newFilters) {
        const prevFilter = prevFilters.find((f) => f.id === filter.id);
        if (!prevFilter || prevFilter.value !== filter.value) {
          flag = true;
          break;
        }
      }

      if (flag) {
        setPagination((prevPagination) => ({
          ...prevPagination,
          pageIndex: 0,
        }));
      }

      return newFilters.filter((filter: any) => !distinct_items || distinct_items[filter.id] === undefined || distinct_items[filter.id]?.includes(filter.value));
    });
  }, 500);



  useLayoutEffect(() => {
    // Convert searchParams to a plain object
    const params = Object.fromEntries(searchParams);

    // Rebuild pagination state
    const newPagination = {
      pageIndex: params.offset ? Math.floor(parseInt(params.offset, 10) / (parseInt(params.limit, 10) || 50)) : 0,
      pageSize: params.limit ? parseInt(params.limit, 10) : 50,
    };
    setPagination(newPagination);

    // Rebuild sorting state
    const newSorting: Array<{ id: string; desc: boolean }> = [];
    Object.entries(params).forEach(([key, value]) => {
      const match = key.match(/^sorts\[(\d+)]\[(.+)]$/);
      if (match) {
        const [, , field] = match;
        newSorting.push({
          id: field,
          desc: value === 'DESC',
        });
      }
    });
    setSorting(newSorting);

    // Rebuild filters
    const newColumnFilters: any = [];
    Object.entries(params).forEach(([key, value]) => {
      if (key.startsWith('filters[')) {
        const fieldMatch = key.match(/^filters\[(.+)]\[(.+)]$/);
        if (fieldMatch) {
          const [, field, operator] = fieldMatch;
          const isDateFilter = ['gte', 'lte'].includes(operator);

          let parsedValue: any = value;
          if (isDateFilter && moment(value, moment.ISO_8601, true).isValid()) {
            parsedValue = moment(value);
          }

          const existingFilter = newColumnFilters.find((filter: any) => filter.id === field);
          if (existingFilter) {
            existingFilter.value[operator === 'gte' ? 0 : 1] = parsedValue;
          } else if (operator === 'gte' || operator === 'lte') {
            const range = operator === 'gte' ? [parsedValue, undefined] : [undefined, parsedValue];
            newColumnFilters.push({ id: field, value: range });
          } else {
            newColumnFilters.push({ id: field, value: parsedValue });
          }
        }
      }
    });

    setColumnFilters(newColumnFilters);
  }, [searchParams]);




  const handleExport = async (table: MRT_TableInstance<any>) => {
    const headers = columns.map((column) => column.header);
    searchParams.set("limit", total_rows?.toString() ?? "9999999");
    searchParams.set("offset", "0");
    const reponse = await axios.get(getUrl, { params: searchParams });
    if (rowPreperation) {
      reponse.data.data.forEach(rowPreperation);
    }
    const excelData = [
      headers,
      ...reponse.data.data.map((row: any) => columns.map((column) => column.accessorFn ? column.accessorFn(row) : row[column.accessorKey ?? ''])),
    ];
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
    setAnchorEl(null);
  };


  columns.forEach((column) => {
    if (distinct_items && column.accessorKey !== undefined && column.accessorKey in distinct_items) {
      column.filterSelectOptions = distinct_items[column.accessorKey];
    }
  });


  return (
    <MaterialReactTable
      columns={columnsMemo}
      data={data}
      state={{
        columnFilters,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
      localization={MRT_Localization_HE}
      enableColumnResizing
      enableGlobalFilter={false}
      enableColumnOrdering
      columnResizeDirection="rtl"
      columnFilterDisplayMode='popover'
      enableColumnDragging={false}
      initialState={{
        density: "compact",
      }}
      getRowId={getRowId}
      paginationDisplayMode="pages"
      muiTableContainerProps={{ ref: contentRef }}
      enableRowActions={Boolean(actions)}
      renderRowActions={actions}
      muiPaginationProps={{
        color: "secondary",
        rowsPerPageOptions: [
          10, 25, 50, 100, 200
        ],
        shape: "rounded",
        variant: "outlined",
      }}
      defaultColumn={{
        muiTableBodyCellProps: { align: "left", sx: { fontSize: "small" } },
        muiCopyButtonProps: {
          sx: { fontWeight: 500 },
        },
        size: 100,
      }}
      layoutMode="grid"
      enableColumnPinning
      enableClickToCopy
      enableRowSelection={false}
      enableEditing={false}
      renderTopToolbarCustomActions={({ table }) => (
        <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={3}>
          <Button
            color="primary"
            onClick={(e: any) => setAnchorEl(e.currentTarget)}
            startIcon={<Iconify icon="mdi:export-variant" />}
          >
            ייצוא
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                handleExport(table);
              }}
            >
              קובץ אקסל
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                reactToPrintFn();
                setAnchorEl(null);
              }}
            >
              הדפס
            </MenuItem>
          </Menu>
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            {`מציג ${data.length} מתוך ${total_rows ?? 0} רשומות`}
          </Typography>
        </Stack>
      )}
      manualFiltering={true}
      manualPagination={true}
      manualSorting={true}
      muiToolbarAlertBannerProps={isError
        ? {
          color: 'error',
          children: 'Error loading data',
        }
        : undefined}
      onColumnFiltersChange={handleColumnFiltersChange}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={total_rows ?? 0}
      muiTablePaperProps={({ table }) => ({
        style: {
          zIndex: table.getState().isFullScreen ? 1500 : undefined,
        },
      })}

    />
  );
};

export default DataTableServerSide;
